import {PermissionType} from '@hconnect/apiclient'

import {MainRoutes, mainRoutes, RouteName} from '../../routing'
import {PlannerFeature} from '../enums'
import {PermissionParsed, PlannerDataScope} from '../interfaces/api/permissions'

type MainRouteName = keyof MainRoutes

// 1:1 mapping of a page to a feature.  Not all features map to pages (some control functionality within a page) but all pages map to a feature.
const pageToFeatureMap: Record<MainRouteName, PlannerFeature> = {
  [RouteName.STOCK]: PlannerFeature.Stock,
  [RouteName.PRODUCTION_PLANNING]: PlannerFeature.Planning,
  [RouteName.SALES_FORECAST]: PlannerFeature.DemandForecast,
  [RouteName.ELECTRICITY_FORECAST]: PlannerFeature.PriceForecast,
  [RouteName.OPTIMIZER]: PlannerFeature.Optimizer,
  [RouteName.OPERATOR_VIEW]: PlannerFeature.ControlOperatorScreen
}

interface PermissionDetail {
  permissionTypes: [PermissionType, ...PermissionType[]]
  operator: 'AND' | 'OR'
}

export const mainPagesToPermissionsMap: Record<MainRouteName, PermissionDetail> = {
  [RouteName.STOCK]: {
    permissionTypes: ['VIEW_MATERIAL_STORAGE', 'VIEW_MACHINE_PLAN'],
    operator: 'AND'
  },
  [RouteName.ELECTRICITY_FORECAST]: {
    permissionTypes: ['VIEW_ELECTRICITY_PRICE'],
    operator: 'AND'
  },
  [RouteName.SALES_FORECAST]: {
    permissionTypes: ['VIEW_DEMAND'],
    operator: 'AND'
  },
  [RouteName.PRODUCTION_PLANNING]: {
    permissionTypes: ['CHANGE_MACHINE_PLAN', 'CHANGE_ELECTRICITY_ORDER'],
    operator: 'OR'
  },
  [RouteName.OPTIMIZER]: {
    permissionTypes: ['CHANGE_MACHINE_PLAN'],
    operator: 'AND'
  },
  [RouteName.OPERATOR_VIEW]: {
    permissionTypes: ['VIEW_MACHINE_PLAN'],
    operator: 'AND'
  }
}

export interface PageAvailabilityDetail {
  path: MainRoutes[MainRouteName]
  routeName: MainRouteName
  isAvailable: boolean
  hasPermission: boolean
}

export type GetPageAvailability = (
  availableFeatures: PlannerFeature[],
  permissions: PermissionParsed[],
  countryId: string,
  plantCode: string
) => PageAvailabilityDetail[]

export const getPageAvailability: GetPageAvailability = (
  availableFeatures,
  permissions,
  countryId,
  plantCode
) => {
  const isFeatureAvailable = (page: MainRouteName) =>
    availableFeatures.includes(pageToFeatureMap[page])

  const userHasPermission = (page: MainRouteName) =>
    checkIfMultiplePermissionsGranted({
      requiredPermissionTypes: mainPagesToPermissionsMap[page].permissionTypes,
      permissions,
      operator: mainPagesToPermissionsMap[page].operator,
      testFn: (permission) =>
        checkPermissionAccessByCountryOrPlantId(countryId, plantCode, permission.parsedDataScope)
    })

  return Object.entries(mainRoutes).reduce<PageAvailabilityDetail[]>((acc, [pageName, path]) => {
    acc.push({
      path,
      routeName: pageName as MainRouteName,
      isAvailable: isFeatureAvailable(pageName as MainRouteName),
      hasPermission: userHasPermission(pageName as MainRouteName)
    })
    return acc
  }, [])
}

export const checkPermissionAccessByCountryOrPlantId = (
  countryId: string,
  plantId: string,
  permissionDataScope: Partial<Pick<PlannerDataScope, 'countryId' | 'plantId'>>
) => {
  if (plantId === permissionDataScope.plantId && countryId === permissionDataScope.countryId) {
    return true
  }
  if (!permissionDataScope.plantId && countryId === permissionDataScope.countryId) {
    return true
  }
  return false
}

interface CheckIfMultiplePermissionsGrantedParams {
  permissions: PermissionParsed[]
  requiredPermissionTypes: PermissionType[]
  testFn: (permission: PermissionParsed) => boolean
  operator: 'OR' | 'AND'
}
/**
 * funtion to check if access is granted for multiple permission types
 * permission1 && permission2 or permission1 || permission2
 */
export const checkIfMultiplePermissionsGranted = ({
  requiredPermissionTypes,
  permissions,
  testFn,
  operator
}: CheckIfMultiplePermissionsGrantedParams) => {
  if (!permissions.length || !requiredPermissionTypes.length) {
    return false
  }
  const isGranted = requiredPermissionTypes
    .map((permissionType) =>
      permissions.some(
        (permission) => permission.permissionType === permissionType && testFn(permission)
      )
    )
    [operator === 'AND' ? 'every' : 'some']((isGranted) => isGranted)

  return isGranted
}
