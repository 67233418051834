import {useMemo} from 'react'

import {BaseSchedule} from '../../interfaces/api'
import {mapScheduleItemIdToAssetData} from '../../selectors/assets'
import {MomentRange} from '../../selectors/time'
import {useUrlParam} from '../useUrlParam'

import {usePlannerQuery} from './usePlannerQuery'

interface AssetHistoryDataByScheduleItemIdParams {
  timeFrame: MomentRange
  schedule: BaseSchedule | undefined
}

export const useAssetHistoryDataByScheduleItemId = ({
  timeFrame,
  schedule
}: AssetHistoryDataByScheduleItemIdParams) => {
  const plantCode = useUrlParam('plantCode')
  const [startOfPlan, endOfPlan] = timeFrame
  const {data: assetsHistory} = usePlannerQuery('assetsHistory', [
    plantCode,
    startOfPlan.toISOString(),
    endOfPlan.toISOString()
  ])

  return useMemo(() => {
    if (!schedule || !assetsHistory) {
      return undefined
    }
    return mapScheduleItemIdToAssetData(schedule.schedules, assetsHistory)
  }, [schedule, assetsHistory])
}
