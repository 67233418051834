import {useCallback} from 'react'

import {OptimizedSchedules} from '../../../interfaces/api'
import {usePeriodicAutofillCheck} from '../../usePeriodicAutofillCheck'
import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

interface OptimizedSchedulesQueryParams {
  isDisabled?: boolean
  useErrorBoundary?: boolean
  scheduleId: number | undefined
}

export const useOptimizedScheduleQuery = ({
  isDisabled = false,
  useErrorBoundary = true,
  scheduleId
}: OptimizedSchedulesQueryParams) => {
  const plantCode = useUrlParam('plantCode')

  // schedule is reloaded if there is a change in the autofill status
  usePeriodicAutofillCheck()

  const selector = useCallback(
    (data: OptimizedSchedules) => {
      return data.find((e) => e.scheduleId === scheduleId)
    },
    [scheduleId]
  )

  return usePlannerQuery('optimizedSchedules', [{plantCode}], {
    enabled: !isDisabled && scheduleId !== undefined,
    useErrorBoundary,
    select: selector
  })
}
