import {useNotification} from '@hconnect/uikit/src/common/'
import {useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useCallback} from 'react'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'

import {mutations} from '../../../api/mutations'
import {invalidateHcemQuery} from '../../../api/queryHelpers'
import {notifyIfErrorMessage} from '../../../common'
import {ErrorResponse} from '../../../interfaces/api/common'
import {OptimizerSettings} from '../../../interfaces/api/optimizer'
import {mapOptimizerSettingsToDTO} from '../../../selectors/optimizer'
import {useUrlParam} from '../../useUrlParam'

export const useEditOptimizerSettings = () => {
  const plantCode = useUrlParam('plantCode')
  const {notify} = useNotification()
  const {t} = useTranslation()
  const raiseError = useErrorHandler()
  const {mutate, isLoading} = useMutation<
    void,
    AxiosError<ErrorResponse>,
    Parameters<typeof mutations.editOptimizerSettings>[0]
  >({
    mutationFn: mutations.editOptimizerSettings,
    onError: (error) => {
      notify('error', t('error.apology'))
      notifyIfErrorMessage(error.response?.data.detail, raiseError, notify)
    },
    onSettled: (data, error, variables) => {
      invalidateHcemQuery('optimizerSettings', variables.plantCode)
    },
    onSuccess: () => {
      notify('success', t('success.editOptimizerSettings'))
    }
  })

  const editOptimizerSettings = useCallback(
    (settings: OptimizerSettings) => {
      mutate({plantCode, settings: mapOptimizerSettingsToDTO(settings)})
    },
    [plantCode, mutate]
  )

  return {
    editOptimizerSettings,
    isLoading: isLoading
  }
}
