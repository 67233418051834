import {PickRequired} from '@hconnect/common/types'

import {ForecastUpdatedVia, ShippingType} from '../../enums'

import {AuditFields} from './common'

export type LastOverrideInfo = PickRequired<AuditFields, 'updatedBy' | 'updatedOn'>

export interface DataPerShippingType<T> {
  [ShippingType.Truck]: T
  [ShippingType.Train]: T
  [ShippingType.Ship]: T
}

type LastUpdatesPerType = DataPerShippingType<LastOverrideInfo>

export type DemandForecastPerType = DataPerShippingType<number>

export interface MaterialDemandEntry {
  // forcasted values could be combined or separate, if only Truck is present, it is combined
  predictions: Partial<DemandForecastPerType>
  // includes schedule and user overrides, user overrides are priority
  merged: Partial<DemandForecastPerType>
  // actual values are always separate, even if Ship doesn't exist, it should be 0
  actuals: Partial<DemandForecastPerType> | Record<string, never>
  preOrders: Partial<DemandForecastPerType> | Record<string, never>
  schedules: Partial<DemandForecastPerType>
  userOverrides: Partial<DemandForecastPerType>
  salesForecast: Partial<DemandForecastPerType>
  lastUpdates: Partial<LastUpdatesPerType>
}

export type MaterialDemandById = {[materialId: string]: MaterialDemandEntry}
// day is in format 'YYYY-MM-DD' (plant local date)
export type MaterialDemandByDay = {[day: string]: MaterialDemandById}

export type CombinedDemandKeys = keyof Pick<
  MaterialDemandEntry,
  | 'preOrders'
  | 'actuals'
  | 'merged'
  | 'predictions'
  | 'salesForecast'
  | 'schedules'
  | 'userOverrides'
>

export type CombinedDemandDataPerDay = Record<
  CombinedDemandKeys,
  Record<string, CombinedDemandData>
> &
  Pick<MaterialDemandEntry, 'lastUpdates'>

// type used for demand chart, combines all selected materials data
export type CombinedDemandData = Record<CombinedDemandKeys, Record<string, number>>

export type ExtendedLastUpdate = LastOverrideInfo & {updatedVia: ForecastUpdatedVia}

export type AggregatedDemandData = Pick<
  MaterialDemandEntry,
  | 'preOrders'
  | 'actuals'
  | 'merged'
  | 'predictions'
  | 'salesForecast'
  | 'userOverrides'
  | 'schedules'
> & {
  lastUpdates: Partial<DataPerShippingType<ExtendedLastUpdate>>
}

export type DemandDataPerMaterial = Record<string, AggregatedDemandData>
