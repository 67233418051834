import {useNotification} from '@hconnect/uikit/src/common/'
import {useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useCallback} from 'react'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'

import {mutations} from '../../../api/mutations'
import {invalidateHcemQuery} from '../../../api/queryHelpers'
import {notifyIfErrorMessage} from '../../../common'
import {ErrorResponse} from '../../../interfaces/api/common'
import {useUrlParam} from '../../useUrlParam'

export const useCancelOptimizerRun = () => {
  const plantCode = useUrlParam('plantCode')
  const {notify} = useNotification()
  const {t} = useTranslation()
  const raiseError = useErrorHandler()
  const {mutate, isLoading} = useMutation<
    void,
    AxiosError<ErrorResponse>,
    Parameters<typeof mutations.cancelOptimizerRun>[0]
  >({
    mutationFn: mutations.cancelOptimizerRun,
    onError: (error) => {
      notify('error', t('error.apology'))
      notifyIfErrorMessage(error.response?.data.detail, raiseError, notify)
      invalidateHcemQuery('optimizedSchedules', {plantCode})
    },
    onSuccess: () => {
      notify('success', t('success.cancelOptimizerRun'))
    },
    onSettled: (data, error, {plantCode}) => {
      invalidateHcemQuery('calculatingPlans', plantCode)
    }
  })

  const cancelOptimizerRun = useCallback(
    (runId: number) => mutate({plantCode, runId}),
    [plantCode, mutate]
  )

  return {cancelOptimizerRun, isLoading}
}
