import {Button, ButtonProps, CircularProgress} from '@mui/material'

import {dataTestId} from '../../../common/utils/testingUtils'
import {mergeSxs} from '../../utils'

interface LoadingButtonProps extends ButtonProps {
  isLoading?: boolean
}

export function LoadingButton({isLoading, startIcon, sx, ...props}: LoadingButtonProps) {
  return (
    <Button
      {...props}
      startIcon={
        isLoading ? (
          <CircularProgress
            size="sm"
            sx={{width: (theme) => theme.spacing(2), color: 'currentcolor'}}
            {...dataTestId('loading_button_spinner')}
          />
        ) : (
          startIcon
        )
      }
      sx={mergeSxs({mWidth: (theme) => theme.spacing(15)}, sx)}
    >
      {props.children}
    </Button>
  )
}
