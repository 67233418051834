import type {CartesianPoint, BarycentricCoordinates} from './barycentric.types'
/**
 * function to calculate the Cartesian coordinates
 * from the barycentric coordinates and the vertices of a triangle.
 * @param vertices - an array of three points representing the vertices of the triangle
 * @param barycentricCoordinates - an array of three numbers representing the barycentric coordinates
 * @returns a point representing the Cartesian coordinates
 * @example
 *  const point = calculateCartesianCoordinates(
 *   [{x: 0, y: 0}, {x: 1, y: 0}, {x: 0, y: 1}],
 *   [0.5, 0.5, 0]
 * )
 * point will be {x: 0.5, y: 0.25}
 * */
export const calculateCartesianCoordinates = (
  vertices: [CartesianPoint, CartesianPoint, CartesianPoint],
  barycentricCoordinates: BarycentricCoordinates
): CartesianPoint => {
  const [alpha, beta, gamma] = barycentricCoordinates
  const [a, b, c] = vertices
  const x = a.x * alpha + b.x * beta + c.x * gamma
  const y = a.y * alpha + b.y * beta + c.y * gamma
  return {x, y}
}

/**
 * function to calculate the distance between two points
 * using the Cartesian distance formula.
 * @param p1 - first point
 * @param p2 - second point
 * @returns the distance between the two points
 * @example
 * const distance = calculateCartesianDistance({x: 1, y: 2}, {x: 4, y: 6})
 * distance will be 5
 */
export const calculateCartesianDistance = (p1: CartesianPoint, p2: CartesianPoint): number => {
  const dx = p1.x - p2.x
  const dy = p1.y - p2.y
  return Math.sqrt(dx * dx + dy * dy)
}

/**
 * function to calculate barycentric coordinates
 * given the vertices of a triangle and a point inside the triangle.
 * @param vertices - an array of three points representing the vertices of the triangle
 * @param p - a point inside the triangle
 * @returns an array of three numbers representing the barycentric coordinates
 */
export const calculateBarycentricCoordinates = (
  vertices: [CartesianPoint, CartesianPoint, CartesianPoint],
  p: CartesianPoint
): BarycentricCoordinates => {
  const [a, b, c] = vertices
  const denominator = (b.y - c.y) * (a.x - c.x) + (c.x - b.x) * (a.y - c.y)
  const alpha = ((b.y - c.y) * (p.x - c.x) + (c.x - b.x) * (p.y - c.y)) / denominator
  const beta = ((c.y - a.y) * (p.x - c.x) + (a.x - c.x) * (p.y - c.y)) / denominator
  const gamma = 1 - alpha - beta
  return [alpha, beta, gamma]
}

/**
 * function to validate barycentric coordinates
 * to ensure they are within the range [0, 1]
 * and their sum is less than or equal to 1.
 * This is useful for ensuring that the coordinates
 * are valid for barycentric interpolation.
 * @param coords
 * @returns boolean indicating if the coordinates are valid
 * @example
 * const coords = validateBarycentricCoordinates([0.5, 0.5, 0])
 * coords will be true
 */
export const validateBarycentricCoordinates = (coords: BarycentricCoordinates): boolean => {
  const [alpha, beta, gamma] = coords
  const isValidCoordinate = (coord: number) => coord >= 0 && coord <= 1
  const isValidSum = alpha + beta + gamma <= 1
  return (
    isValidCoordinate(alpha) && isValidCoordinate(beta) && isValidCoordinate(gamma) && isValidSum
  )
}

/**
 * function to adjust barycentric coordinates
 * to ensure they are within the range [0, 1]
 * and their sum is exactly 1.
 * This is useful for ensuring that the coordinates
 * are valid for barycentric interpolation.
 * @param coords
 * @returns adjusted barycentric coordinates
 * @example
 * const coords = adjustBarycentricCoordinates([1.2, -0.5, 0.3])
 * coords will be [0.8, 0, 0.2]
 */
export const adjustBarycentricCoordinates = (
  coords: BarycentricCoordinates
): BarycentricCoordinates => {
  let [alpha, beta, gamma] = coords
  alpha = Math.max(0, alpha)
  beta = Math.max(0, beta)
  gamma = Math.max(0, gamma)
  const sum = alpha + beta + gamma
  if (Number(sum.toFixed(2)) === 0) {
    return [1 / 3, 1 / 3, 1 / 3]
  }
  // Always normalize to make sum exactly 1
  alpha /= sum
  beta /= sum
  gamma /= sum
  return [alpha, beta, gamma]
}
