import {OptimizerRunStatus, PlannerForecastType} from '../../enums'

export interface OptimizerSettingsDTO {
  // number values from 0 to 1
  switch_cost_priority: number
  silo_slack_priority: number
  // electricity cost priority is not returned in the API
  // should be calculated like this: 1 - switch_cost_priority - silo_slack_priority
}

export interface OptimizerSettings {
  switchCostPriority: number
  siloSlackPriority: number
  electricityCostPriority: number
}

export interface RecalculatePlanDTO {
  name: string
  forecast_type: PlannerForecastType.StatisticalForecast
  settings: OptimizerSettingsDTO
  save_settings: boolean
}

export interface RecalculatePlanData {
  name: string
  forecastType: PlannerForecastType.StatisticalForecast
  optimizerSettings: OptimizerSettings
  saveSettings: boolean
}

export interface OptimizerRunResponse {
  name: string
  forecast_type: PlannerForecastType.StatisticalForecast
  status: OptimizerRunStatus
  last_updated_at: string
  queued_at: string
  queued_by: string
  optimizer_run_id: number
}

export interface OptimizerRun {
  name: string
  forecastType: PlannerForecastType.StatisticalForecast
  status: OptimizerRunStatus
  lastUpdatedAt: string
  queuedAt: string
  queuedBy: string
  optimizerRunId: number
}
