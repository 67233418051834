import type {MouseEvent as SyntheticMouseEvent, TouchEvent as SyntheticTouchEvent} from 'react'

import type {SliderKey} from './types'
import type {NumberRange} from './utils'
import {clamp} from './utils'

// Convert a point into a percentage value
export const getPercentageFromPosition = (position: number, clientRect: DOMRect) => {
  const length = clientRect.width
  const sizePercentage = position / length

  return sizePercentage || 0
}

// Convert a point into a model value
export const getValueFromPosition = (
  position: number,
  [minValue, maxValue]: NumberRange,
  clientRect: DOMRect
): number => {
  const sizePercentage = getPercentageFromPosition(position, clientRect)
  const valueDiff = maxValue - minValue

  return minValue + valueDiff * sizePercentage
}

// Convert a model value into a percentage value
export const getPercentageFromValue = (value: number, [min, max]: NumberRange) => {
  const validValue = clamp(value, [min, max])
  const valueDiff = max - min
  const valuePercentage = (validValue - min) / valueDiff

  return valuePercentage || 0
}

// Convert model values into percentage values
export const getPercentagesFromValues = (values: NumberRange, minMax: NumberRange): NumberRange => [
  getPercentageFromValue(values[0], minMax),
  getPercentageFromValue(values[1], minMax)
]

export const getPositionFromValue = (
  value: number,
  [min, max]: NumberRange,
  clientRect: DOMRect
): number => {
  const length = clientRect.width
  const valuePercentage = getPercentageFromValue(value, [min, max])
  return valuePercentage * length
}

// Convert a range of values into points
export const getPositionsFromSteps = (
  [minVal, maxVal]: NumberRange,
  [minRange, maxRange]: NumberRange,
  clientRect: DOMRect
): NumberRange => [
  getPositionFromValue(minVal, [minRange, maxRange], clientRect),
  getPositionFromValue(maxVal, [minRange, maxRange], clientRect)
]

// Convert a value into a step value
export const getStepValueFromValue = (value, valuePerStep) =>
  Math.round(value / valuePerStep) * valuePerStep

// Get the x position of an event on a scale of 0 - track width
export const getPositionFromEvent = (
  event: MouseEvent | TouchEvent | SyntheticMouseEvent | SyntheticTouchEvent,
  clientRect: DOMRect
) => {
  const length = clientRect.width
  const anyEvent = event as MouseEvent & TouchEvent & SyntheticMouseEvent & SyntheticTouchEvent
  const clientX = anyEvent.touches ? anyEvent.touches[0].clientX : anyEvent.clientX

  return clamp(clientX - clientRect.left, [0, length])
}

export const getValueFromKey = (key: SliderKey, values: NumberRange) => {
  const [minVal, maxVal] = values
  const isMin = key === 'min'
  return isMin ? minVal : maxVal
}
