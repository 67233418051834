import {Moment} from 'moment-timezone'

import {OptimizedSchedule, Schedule} from '../../../interfaces/api'
import {usePeriodicAutofillCheck} from '../../usePeriodicAutofillCheck'

import {useCurrentScheduleQuery} from './useCurrentScheduleQuery'
import {useOptimizedScheduleQuery} from './useOptimizedScheduleQuery'

interface UseScheduleQueryParams {
  // pass scheduleId for optimized schedule
  scheduleId?: number | undefined
  useErrorBoundary?: boolean
  range: [Moment, Moment]
}
interface UseScheduleQueryResult<T extends number | undefined> {
  data: (T extends number ? OptimizedSchedule : T extends undefined ? Schedule : never) | undefined
  isFetching: boolean
}

export const useScheduleQuery = <T extends number | undefined>({
  scheduleId,
  useErrorBoundary = true,
  range
}: UseScheduleQueryParams): UseScheduleQueryResult<T> => {
  // schedule is reloaded if there is a change in the autofill status
  usePeriodicAutofillCheck()

  const {data: currentSchedule, isFetching: isCurrentScheduleFetching} = useCurrentScheduleQuery({
    range
  })

  const {data: optimizedSchedule, isFetching: isOptimizedScheduleFetching} =
    useOptimizedScheduleQuery({
      scheduleId,
      isDisabled: scheduleId === undefined,
      useErrorBoundary
    })

  return {
    data: scheduleId !== undefined ? optimizedSchedule : currentSchedule,
    isFetching: isCurrentScheduleFetching || isOptimizedScheduleFetching
  } as UseScheduleQueryResult<T>
}
