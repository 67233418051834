import {ONE_MINUTE_REFRESH_INTERVAL} from '../../../constants'
import {mapCalculatingPlansResponse} from '../../../selectors/optimizer'
import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

export const useCalculatingPlans = () => {
  const plantCode = useUrlParam('plantCode')

  return usePlannerQuery('calculatingPlans', [plantCode], {
    select: mapCalculatingPlansResponse,
    refetchInterval: ONE_MINUTE_REFRESH_INTERVAL
  })
}
