import type {Moment} from 'moment-timezone'

import type {Iso8601, LastSubmitted} from './common'

export interface EnergyConsumptionResponse {
  datetime: Iso8601
  power: number
}

export interface PowerConsumption {
  dateTimeIso: Iso8601
  dateTimeUTC: Moment
  power: number
}

export interface ElectricityResponse {
  planned: EnergyConsumptionResponse[]
  submitted: EnergyConsumptionResponse[]
  nextPurchasingDeadline: Iso8601
  // for non submitting plants it will be undefined
  lastSubmitted?: LastSubmitted
}

export interface Electricity
  extends Pick<ElectricityResponse, 'nextPurchasingDeadline' | 'lastSubmitted'> {
  planned: PowerConsumption[]
  submitted: PowerConsumption[]
  purchased: PowerConsumption[]
  upForPurchasing: PowerConsumption[]
}
