import moment, {Moment} from 'moment-timezone'
import {useMemo} from 'react'

import {CommentsCategory} from '../../../enums'
import {ScheduleItem, Comment} from '../../../interfaces/api'
import {useCommentsPermissions} from '../../permissions'
import {usePlantConfig} from '../../usePlantConfigData'
import {useHistoryAssetsQuery} from '../assets'
import {useScheduleCommentsQuery} from '../comments'
import {useAssetCommentsQuery} from '../comments/useAssetsCommentsQuery'
import {useCurrentScheduleQuery} from '../schedules/useCurrentScheduleQuery'

interface UsePlanningCommentsParams {
  planTimeframe: [Moment, Moment]
  scheduleItemRange: [Moment, Moment]
}

export const usePlanningComments = ({
  planTimeframe: [startOfPlan, endOfPlan],
  scheduleItemRange: [startOfSelection, endOfSelection]
}: UsePlanningCommentsParams) => {
  const latest = usePlantConfig()

  const {timezone_id: timezoneId} = latest
  const {data: schedule} = useCurrentScheduleQuery({
    range: [startOfPlan, endOfPlan]
  })
  const {data: assets} = useHistoryAssetsQuery({
    timeFrame: [startOfPlan, endOfPlan],
    sorted: true
  })

  const scheduleItems: ScheduleItem[] = useMemo(
    () => Object.values(schedule?.schedules ?? {}),
    [schedule]
  )

  const scheduleItemIds = useMemo(() => scheduleItems.map(({id}) => id), [scheduleItems])
  const selectedScheduleItemIds = useMemo(
    () =>
      scheduleItems
        // filter schedule items that are within slider selection
        .filter(
          (scheduleItem) =>
            moment.utc(scheduleItem.start).tz(timezoneId).isBefore(endOfSelection) &&
            moment.utc(scheduleItem.end).tz(timezoneId).isAfter(startOfSelection)
        )
        .map(({id}) => id),
    [scheduleItems, startOfSelection, endOfSelection, timezoneId]
  )

  const {canViewComments: canViewAssetComments} = useCommentsPermissions(CommentsCategory.Assets)
  const {canViewComments: canViewScheduleComments} = useCommentsPermissions(
    CommentsCategory.AssetOperationTimes
  )
  // used isInitialLoading to prevent showing skeleton loader when query is disabled. This behaviour is fixed in react query v5
  const {data: assetComments, isInitialLoading: areAssetCommentsLoading} = useAssetCommentsQuery(
    assets?.map((asset) => asset.id),
    !canViewAssetComments
  )
  const {data: scheduleComments, isInitialLoading: areScheduleCommentsLoading} =
    useScheduleCommentsQuery({
      scheduleItemIds,
      isDisabled: !canViewScheduleComments,
      selectedScheduleItemIds
    })
  const isLoadingComments = areAssetCommentsLoading || areScheduleCommentsLoading

  const comments = useMemo(() => {
    if (isLoadingComments || !assetComments || !scheduleComments) {
      return undefined
    }

    const comments: Comment[] = [...assetComments, ...scheduleComments]
    const sortedComments = comments.sort((a, b) => {
      if ((a.updatedOn ?? a.createdOn) < (b.updatedOn ?? b.createdOn)) return 1
      if ((a.updatedOn ?? a.createdOn) > (b.updatedOn ?? b.createdOn)) return -1
      return 0
    })

    return sortedComments
  }, [assetComments, scheduleComments, isLoadingComments])

  return {
    comments,
    isLoading: isLoadingComments
  }
}
