export type Iso8601 = string

export interface LastSaved {
  savedAt: Iso8601
  savedBy: string
  savedById: string
}

export interface LastSubmitted {
  submittedAt: Iso8601
  submittedBy: Iso8601
}

// Error related types and interfaces
export type ErrorResponse<F extends string = string, S extends string = string> = {
  detail?: string
  message?: string
  errors: Partial<{[key in F]: string}>
  status: S
}

export type MutationError<T> = ErrorResponse<keyof T>

export interface AuditFields {
  createdOn: Iso8601
  createdBy: string
  updatedOn?: Iso8601
  updatedBy?: string
}
