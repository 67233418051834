import {MomentRange} from '../../../selectors/time'
import {usePeriodicAutofillCheck} from '../../usePeriodicAutofillCheck'
import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

interface CurrentScheduleQueryParams {
  isDisabled?: boolean
  useErrorBoundary?: boolean
  range: MomentRange
}

export const useCurrentScheduleQuery = ({
  isDisabled = false,
  useErrorBoundary = true,
  range: [startOfPlan, endOfPlan]
}: CurrentScheduleQueryParams) => {
  const plantCode = useUrlParam('plantCode')

  // schedule is reloaded if there is a change in the autofill status
  usePeriodicAutofillCheck()

  return usePlannerQuery(
    'schedule',
    [{plantCode, start: startOfPlan.toISOString(), end: endOfPlan.toISOString()}],
    {enabled: !isDisabled, useErrorBoundary}
  )
}
