import type {Attachment} from '@hconnect/common/types'

import {MaterialOrderStatus} from '../../enums'

import {AuditFields} from './common'

export type MaterialOrderAttachment = Attachment & {sizeInByte: number}

export interface MaterialDelivery {
  id: string
  amountDelivered: number
  deliveredOn: string
  documentNumber: string
}

export interface MaterialOrder extends AuditFields {
  id: number
  materialId: number
  scheduleAgreement: ScheduleAgreementDataResponse
  amount: number
  deliveries: MaterialDelivery[]
  scheduledDate: string
  status: MaterialOrderStatus
  attachments: MaterialOrderAttachment[]
}

export type CreateMaterialOrder = Pick<MaterialOrder, 'materialId'> & EditMaterialOrder
export type EditMaterialOrder = Pick<MaterialOrder, 'scheduledDate' | 'amount'> & {
  scheduleAgreement: ScheduleAgreementDataWithVendor | null
}

export interface ScheduleAgreementDataResponse {
  vendorName: string
  vendorId?: string
  documentNumber?: string
  positionNumber?: string
}
// some SAP instances do not have vendor information
export type ScheduleAgreementDataWithoutVendor = Pick<ScheduleAgreementDataResponse, 'vendorName'>
export type ScheduleAgreementDataWithVendor = Required<ScheduleAgreementDataResponse>

export interface ScheduleAgreement {
  documentNumber: string
  validFrom: string
  validTo: string
  positionNumber: string[]
}
export interface ScheduleAgreementsByVendor {
  vendorId: string
  vendorName: string
  materialCode: string
  scheduleAgreements: ScheduleAgreement[]
}

export interface GroupedUnplannedDelivery {
  // this entity doesn't have an id because it's grouped on BE for presentation purpose
  materialId: number
  amountDelivered: number
  deliveredOn: string
  scheduleAgreement: Omit<ScheduleAgreementDataResponse, 'positionNumber'>
  deliveries: MaterialUnplannedDelivery[]
}

export interface MaterialUnplannedDelivery
  extends Pick<MaterialDelivery, 'documentNumber' | 'deliveredOn' | 'amountDelivered'> {
  id: number
  materialId: number
}
