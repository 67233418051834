import {OptimizerRunStatus} from '../enums'
import {
  OptimizerSettings,
  OptimizerSettingsDTO,
  OptimizerRun,
  OptimizerRunResponse
} from '../interfaces/api/optimizer'

const roundTo = (num: number, places: number) => {
  const factor = Math.pow(10, places)
  return Math.round(num * factor) / factor
}

const decimalPlaces = 5

export const mapOptimizerSettingsResponse = (response: OptimizerSettingsDTO): OptimizerSettings => {
  const {switch_cost_priority, silo_slack_priority} = response
  if (switch_cost_priority + silo_slack_priority > 1) {
    throw new Error('BUG:Switch cost priority and silo slack priority sum cannot be greater than 1')
  }

  return {
    switchCostPriority: switch_cost_priority,
    siloSlackPriority: silo_slack_priority,
    electricityCostPriority: roundTo(1 - switch_cost_priority - silo_slack_priority, decimalPlaces)
  }
}

export const mapOptimizerSettingsToDTO = (settings: OptimizerSettings): OptimizerSettingsDTO => {
  const {switchCostPriority, siloSlackPriority} = settings
  if (switchCostPriority + siloSlackPriority > 1) {
    throw new Error('BUG:Switch cost priority and silo slack priority sum cannot be greater than 1')
  }

  return {
    switch_cost_priority: roundTo(switchCostPriority, decimalPlaces),
    silo_slack_priority: roundTo(siloSlackPriority, decimalPlaces)
  }
}

export const mapCalculatingPlansResponse = (response: OptimizerRunResponse[]): OptimizerRun[] => {
  return (
    response
      // DS BE returns all runs, even finished ones for 3 hours
      .filter((plan) => plan.status === OptimizerRunStatus.InProgress)
      .map((plan) => ({
        name: plan.name,
        forecastType: plan.forecast_type,
        status: plan.status,
        lastUpdatedAt: plan.last_updated_at,
        queuedAt: plan.queued_at,
        queuedBy: plan.queued_by,
        optimizerRunId: plan.optimizer_run_id
      }))
  )
}
