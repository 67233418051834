import {ONE_MINUTE_REFRESH_INTERVAL} from '../../../constants'
import {usePeriodicAutofillCheck} from '../../usePeriodicAutofillCheck'
import {useUrlParam} from '../../useUrlParam'
import {usePlannerQuery} from '../usePlannerQuery'

interface OptimizedSchedulesQueryParams {
  isDisabled?: boolean
  useErrorBoundary?: boolean
}

export const useOptimizedSchedulesQuery = ({
  isDisabled = false,
  useErrorBoundary = true
}: OptimizedSchedulesQueryParams = {}) => {
  const plantCode = useUrlParam('plantCode')

  // schedule is reloaded if there is a change in the autofill status
  usePeriodicAutofillCheck()

  return usePlannerQuery('optimizedSchedules', [{plantCode}], {
    enabled: !isDisabled,
    useErrorBoundary,
    refetchInterval: ONE_MINUTE_REFRESH_INTERVAL
  })
}
