import {useNotification} from '@hconnect/uikit'
import {useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useTranslation} from 'react-i18next'

import {mutations} from '../../../api/mutations'
import {invalidateHcemQuery, removeHcemQuery} from '../../../api/queryHelpers'
import {useTrackPlannerEvent} from '../../../helpers/trackPlannerEvents'
import {MutationError} from '../../../interfaces/api'
import {usePlannerErrorHandler} from '../../usePlannerErrorHandler'

export const useAcceptOptimizedPlan = () => {
  const {notify} = useNotification()
  const {t} = useTranslation()
  const onError = usePlannerErrorHandler()
  const trackPlannerEvent = useTrackPlannerEvent()
  return useMutation<
    void,
    AxiosError<MutationError<string>>,
    Parameters<typeof mutations.sendToOptimizedPlan>[0]
  >({
    mutationFn: mutations.sendToOptimizedPlan,
    onError: (err) => onError(err),
    onSuccess: () => {
      trackPlannerEvent({name: 'onAcceptOptimizedPlan'})
      notify('success', t('optimizer.acceptOptimizedPlanNotification'))
    },
    onSettled: (data, error, {plantCode}) => {
      invalidateHcemQuery('schedule')
      invalidateHcemQuery('electricity', {plantCode})
      removeHcemQuery('stockDevelopment')
      invalidateHcemQuery('costAvoidanceForRange')
      invalidateHcemQuery('costAvoidanceDaily')
      invalidateHcemQuery('costAvoidanceMonthly')
    }
  })
}
