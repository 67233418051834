import {AssetType} from '@hconnect/common/types'

import {AssetOperationTimeSource, OptimizerScheduleStatus, OptimizerStatus} from '../../enums'
import type {DatetimeValue} from '../common'

import type {Iso8601, LastSaved, AuditFields} from './common'

export interface SelectedSilos {
  isOrderSpecified: boolean
  ids: number[]
}

export interface ScheduleItem extends Required<Pick<AuditFields, 'updatedOn' | 'updatedBy'>> {
  id: string
  start: Iso8601
  end: Iso8601
  assetOperationModeId: number
  assetId: number
  isShutdownAvailable: boolean
  isTransitionTime: boolean
  selectedSilos: SelectedSilos
  isFixed: boolean
  source: AssetOperationTimeSource
}

export type CreateUpdateScheduleItem = Pick<
  ScheduleItem,
  'start' | 'end' | 'assetOperationModeId' | 'isTransitionTime' | 'selectedSilos' | 'isFixed'
>

export type ScheduleItemDict = Record<string, ScheduleItem>

export type ScheduleOtimizationRange = {from: Iso8601; to: Iso8601}

export interface Schedule {
  schedules: ScheduleItemDict
  scheduleId: number
  lastSaved: LastSaved
  isOptimizedScheduleAvailable: boolean
  submissionInfo?: {start: Iso8601; end: Iso8601}
  optimizationRange?: ScheduleOtimizationRange
}

interface OptimizationDetails {
  range: {
    start: Iso8601
    end: Iso8601
  }
  name: string
  validTo: Iso8601
  triggeredBy: string
  triggeredOn: Iso8601
  optimizerStatus: OptimizerStatus
  message?: string
}

type OptimizedScheduleStatusType =
  | OptimizerScheduleStatus.Accepted
  | OptimizerScheduleStatus.Pending

export type BaseSchedule = Omit<
  Schedule,
  'isOptimizedScheduleAvailable' | 'submissionInfo' | 'optimizationRange'
>

export interface OptimizedSchedule extends BaseSchedule {
  status: OptimizedScheduleStatusType
  optimizationDetails: OptimizationDetails
}

export interface UpdateScheduleItemResponse {
  splitItemId?: string
}

export interface SubmitSchedule {
  scheduleId: number
}

export type OptimizedSchedules = OptimizedSchedule[]

type AllAssetTypes =
  | AssetType.CementMill
  | AssetType.CoalMill
  | AssetType.Crusher
  | AssetType.Other
  | AssetType.RawMill
  | AssetType.RotaryKiln

type ProducedTonnesPerAsset = Partial<
  Record<
    | AssetType.CementMill
    | AssetType.CoalMill
    | AssetType.Crusher
    | AssetType.Other
    | AssetType.RawMill
    | AssetType.RotaryKiln,
    number
  >
>

export interface ScheduleCostAvoidance {
  id: number
  date: string
  plantCode: string
  createdOn: string
  totalCost: number
  currency: string
  producedTonnes: ProducedTonnesPerAsset
  electricityConsumed: number
  electricityAverageMarketPrice: number
  isOptimizerUsed: boolean
  baseLoad: number
  costAvoidance: number
  isCostAvoidanceNegative: boolean
}

export type OptimizerUsageForDay = {day: string} & Pick<ScheduleCostAvoidance, 'isOptimizerUsed'>

export interface CostAvoidanceMonthly
  extends Omit<ScheduleCostAvoidance, 'isOptimizerUsed' | 'baseLoad'> {
  percentOfWorldWide: number // % of this plant in all plants cost avoidance for this month
  percentOfYear: number // % of this plant month cost avoidance of whole year
  days: OptimizerUsageForDay[]
}

export interface CostAvoidanceAssetValuesForRange {
  electricityCost: number
  costAvoidance: number
  producedTonnes: number
  electricityConsumed: number
}

export interface CostAvoidanceForRange {
  currency: string
  baseload: number
  electricityAverageMarketPrice: number
  assets: Record<AllAssetTypes, CostAvoidanceAssetValuesForRange>
}

export interface StockDevelopmentEntry {
  actual: DatetimeValue[]
  forecast: DatetimeValue[]
}

export type StockDevelopmentResponse = Record<string, StockDevelopmentEntry>

export type StockReachEntry = {
  date?: string
}
export type StockReachResponse = {
  forecast: Record<string, StockReachEntry>
}

export const isOptimizedScheduleGuard = (
  schedule: OptimizedSchedule | Schedule | undefined
): schedule is OptimizedSchedule | undefined =>
  (schedule as OptimizedSchedule)?.optimizationDetails !== undefined &&
  (schedule as OptimizedSchedule)?.status !== undefined
