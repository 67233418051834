import {useCallback} from 'react'
import moment, {Moment} from 'moment-timezone'
import {ScaleTime} from 'd3'
import {SliderKey} from '../RangeSlider/types'

type PlannerSliderRangeRoundingParams = {
  xScaleCells: ScaleTime<number, number, never>
  timezoneId: string
  roundTime: (key: SliderKey, v: [Moment, Moment]) => [Moment, Moment]
}

export function useDayRangeSliderRounding({
  xScaleCells,
  timezoneId,
  roundTime
}: PlannerSliderRangeRoundingParams) {
  return useCallback(
    (key: SliderKey, [start, end]: [number, number]) => {
      const startDate = moment.utc(xScaleCells.invert(start)).tz(timezoneId)
      const endDate = moment.utc(xScaleCells.invert(end)).tz(timezoneId)

      const [roundedStartDate, roundedEndDate] = roundTime(key, [startDate, endDate])

      const startIndex = Math.floor(xScaleCells(roundedStartDate))
      const endIndex = Math.ceil(xScaleCells(roundedEndDate))

      return [startIndex, endIndex] as [number, number]
    },
    [xScaleCells, roundTime, timezoneId]
  )
}
