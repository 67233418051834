import {Stack, Slider, Typography, Box} from '@mui/material'
import React, {useCallback} from 'react'

import {dataTestId} from '../../../../common/utils/testingUtils'

import {adjustBarycentricCoordinates} from './barycentric.helpers'
import type {BarycentricCoordinates} from './barycentric.types'

type SliderConfig = {
  color: string
  icon?: React.ReactNode
  label: string
}

interface BarycentricSlidersInputProps {
  value: BarycentricCoordinates
  setValue: (coordinates: BarycentricCoordinates) => void
  sliderConfig: [alpha: SliderConfig, beta: SliderConfig, gamma: SliderConfig]
  formatTooltipLabel?: (value: number) => number
}

export const BarycentricSlidersInput: React.FC<BarycentricSlidersInputProps> = ({
  value,
  setValue,
  sliderConfig,
  formatTooltipLabel
}) => {
  const handleSliderChange = useCallback(
    (index: number, updatedCoordinate: number) => {
      const newValues: BarycentricCoordinates = [...value]
      newValues[index] = updatedCoordinate
      const adjustedValues = adjustBarycentricCoordinates(newValues)
      setValue(adjustedValues)
    },
    [value, setValue]
  )
  return (
    <Stack spacing={2} sx={{pr: 3, width: 1}}>
      {sliderConfig.map((config, index) => (
        <div key={index}>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            {config.icon && <Box sx={{color: config.color}}>{config.icon}</Box>}
            <Typography variant="body2" color={config.color}>
              {config.label}
            </Typography>
          </Stack>
          <Slider
            value={value[index]}
            onChange={(e, coordinate) => handleSliderChange(index, coordinate as number)}
            aria-label={config.label}
            valueLabelDisplay="auto"
            step={0.05}
            min={0}
            max={1}
            sx={{color: config.color}}
            valueLabelFormat={(value) =>
              formatTooltipLabel ? formatTooltipLabel(value) : Number(value).toFixed(2)
            }
            slotProps={{
              thumb: {
                ...dataTestId(`barycentric_slider_thumb_${index}`)
              }
            }}
            {...dataTestId(`barycentric_slider_${index}`)}
          />
        </div>
      ))}
    </Stack>
  )
}
