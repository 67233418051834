import {CommentsCategory, CommentStatus} from '../../enums'

export interface Comment {
  commentId: string
  commentsCategory: CommentsCategory
  commentsCategoryInstanceId: string
  status: CommentStatus
  createdOn: string
  updatedOn?: string
  createdBy: string
  updatedBy?: string
  value: string
}

export type CreateEditComment = Pick<Comment, 'value'>
