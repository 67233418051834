// Backend schema https://gitlab.hce.heidelbergcement.com/data_science/hcem/blob/master/schemas/schema-web-app.json#L25

import {PlannerFeature} from '../../enums'
import type {DatetimeValue} from '../common'

import {Iso8601} from './common'

export interface ElectricityPrice {
  forecast: DatetimeValue[]
  actual: DatetimeValue[]
  currency: string
}

export interface LatestElectricity {
  price: ElectricityPrice
}

export interface LatestPeakLoadWindow {
  start: Iso8601
  end: Iso8601
  max_power: number // in MW
}

export interface PlantConfigData {
  available_features: PlannerFeature[]
  country: string
  name: string
  created_at: Iso8601
  currency: string
  timezone_id: string
}
