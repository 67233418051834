import {alpha, IconButton, Tooltip, useTheme} from '@mui/material'
import * as d3 from 'd3'
import React, {useMemo, useEffect, useRef} from 'react'
import {v4 as uuidv4} from 'uuid'

import {dataTestId} from '../../../../common/utils/testingUtils'

import {
  calculateBarycentricCoordinates,
  calculateCartesianCoordinates,
  calculateCartesianDistance,
  validateBarycentricCoordinates,
  adjustBarycentricCoordinates
} from './barycentric.helpers'
import type {CartesianPoint, BarycentricCoordinates, CartesianTriangle} from './barycentric.types'

const sqrt3 = Math.sqrt(3)

const scalePoint = (
  point: CartesianPoint,
  width: number,
  height: number,
  padding: number
): CartesianPoint => {
  return {
    x: (point.x / 100) * (width - 2 * padding) + padding,
    y: (point.y / (50 * sqrt3)) * (height - 2 * padding) + padding
  }
}

export type LabelStyle = {
  color: string
  icon: React.ReactNode
  label: string
}

const padding = 32
const arrowStrokeWidth = 2
const pointStrokeWidth = 2
const triangleStrokeWidth = 3
const pointRadius = 10
const verticeIndicatorRadius = 12

const baseVertices: CartesianTriangle = [
  {x: 0, y: 50 * sqrt3},
  {x: 50, y: 0},
  {x: 100, y: 50 * sqrt3}
]

interface BarycentricTriangleProps {
  initialValue: BarycentricCoordinates
  value: BarycentricCoordinates
  setValue: (coordinates: BarycentricCoordinates) => void
  verticeStyles: [alpha: LabelStyle, beta: LabelStyle, gamma: LabelStyle]
  width?: number
}

export const BarycentricTriangleInput: React.FC<BarycentricTriangleProps> = ({
  initialValue: initialBarycentricCoords,
  value: barycentricCoords,
  setValue: setBarycentricCoords,
  verticeStyles,
  width = 380
}) => {
  // needed to make sure that the id is unique for gradients and clip in svg
  const instanceId = useMemo(() => uuidv4(), [])

  const {palette} = useTheme()

  const height = ((width - 2 * padding) * sqrt3) / 2 + 2 * padding

  const initialPoint = useMemo(
    () => calculateCartesianCoordinates(baseVertices, initialBarycentricCoords),
    [initialBarycentricCoords]
  )
  const newPoint = calculateCartesianCoordinates(baseVertices, barycentricCoords)
  // Refs for SVG elements
  const svgRef = useRef<SVGSVGElement>(null)
  const triangleRef = useRef<SVGPolygonElement>(null)
  const dragPointRef = useRef<SVGCircleElement>(null)
  const initialPointRef = useRef<SVGCircleElement>(null)
  const arrowRef = useRef<SVGLineElement>(null)

  const handleReset = React.useCallback(
    (e: React.MouseEvent<SVGCircleElement, MouseEvent>) => {
      // preventing the click event from being triggered
      // when clicking on the initial point
      e.stopPropagation()
      setBarycentricCoords(initialBarycentricCoords)
    },
    [initialBarycentricCoords, setBarycentricCoords]
  )

  const handleTriangleClick = React.useCallback(
    (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      const rect = svgRef.current?.getBoundingClientRect()
      if (rect) {
        const newX = ((event.clientX - rect.left - padding) / (width - 2 * padding)) * 100
        const newY = ((event.clientY - rect.top - padding) / (height - 2 * padding)) * (50 * sqrt3)
        const newPoint = {x: newX, y: newY}
        const coords = calculateBarycentricCoordinates(baseVertices, newPoint)

        if (validateBarycentricCoordinates(coords)) {
          setBarycentricCoords(coords)
        }
      }
    },
    [width, height, setBarycentricCoords]
  )

  const scaledVertices = React.useMemo(
    () => baseVertices.map((v) => scalePoint(v, width, height, padding)),
    [width, height]
  )

  const iconVertices = React.useMemo(
    () => baseVertices.map((v) => scalePoint(v, width, height, verticeIndicatorRadius)),
    [width, height]
  )

  useEffect(() => {
    if (!dragPointRef.current) return
    const selectedDragPoint = d3.select<Element, unknown>(dragPointRef.current)
    selectedDragPoint.call(
      d3
        .drag()
        .on('drag', (event) => {
          selectedDragPoint.attr('style', 'cursor: grabbing')
          const newX = ((event.x - padding) / (width - 2 * padding)) * 100
          const newY = ((event.y - padding) / (height - 2 * padding)) * (50 * sqrt3)
          const coords = calculateBarycentricCoordinates(baseVertices, {x: newX, y: newY})
          const adjustedCoords = adjustBarycentricCoordinates(coords)
          setBarycentricCoords(adjustedCoords)
        })
        .on('end', (event) => {
          const newX = ((event.x - padding) / (width - 2 * padding)) * 100
          const newY = ((event.y - padding) / (height - 2 * padding)) * (50 * sqrt3)
          const coords = calculateBarycentricCoordinates(baseVertices, {x: newX, y: newY})
          const adjustedCoords = adjustBarycentricCoordinates(coords)
          selectedDragPoint.attr('style', 'cursor: grab')
          setBarycentricCoords(adjustedCoords)
        })
    )
  }, [width, height, setBarycentricCoords])

  useEffect(() => {
    const selectedInitialPoint = d3.select(initialPointRef.current)
    const selectedArrow = d3.select(arrowRef.current)
    const selectedDragPoint = d3.select(dragPointRef.current)

    selectedDragPoint
      .attr('cx', scalePoint(newPoint, width, height, padding).x)
      .attr('cy', scalePoint(newPoint, width, height, padding).y)

    selectedInitialPoint
      .attr('cx', scalePoint(initialPoint, width, height, padding).x)
      .attr('cy', scalePoint(initialPoint, width, height, padding).y)

    selectedArrow
      .attr('x1', scalePoint(initialPoint, width, height, padding).x)
      .attr('y1', scalePoint(initialPoint, width, height, padding).y)
      .attr('x2', scalePoint(newPoint, width, height, padding).x)
      .attr('y2', scalePoint(newPoint, width, height, padding).y)
    // hiding arrow if point is too close to the initial point
    const arrowLength = calculateCartesianDistance(initialPoint, newPoint)
    if (arrowLength < 6) {
      selectedArrow.attr('display', 'none')
    } else {
      selectedArrow.attr('display', 'block')
    }
  }, [initialPoint, newPoint, width, height])

  return (
    <div
      style={{position: 'relative', width, height}}
      {...dataTestId('barycentric_triangle_input')}
    >
      <svg
        ref={svgRef}
        width={width}
        height={height}
        onClick={handleTriangleClick}
        role="application"
        aria-label="Barycentric Triangle Input"
      >
        <defs>
          {baseVertices.map((vertice, index) => (
            <radialGradient
              key={index}
              r="100%"
              id={`grad_${instanceId}_${index}`}
              cx={`${vertice.x}%`}
              cy={`${vertice.y}%`}
              fx={`${vertice.x}%`}
              fy={`${vertice.y}%`}
            >
              <stop offset="0%" style={{stopColor: verticeStyles[index].color, stopOpacity: 1}} />
              <stop offset="100%" style={{stopColor: palette.background.paper, stopOpacity: 0}} />
            </radialGradient>
          ))}
          <marker id="arrowhead" markerWidth="6" markerHeight="6" refX="12" refY="3" orient="auto">
            <polygon points="0 0, 6 3, 0 6" />
          </marker>
        </defs>
        <g clipPath={`url(#clip_${instanceId})`}>
          {
            // Background gradients for the triangle
            baseVertices.map((vertice, index) => (
              <rect
                width={'100%'}
                height="100%"
                fill={`url(#grad_${instanceId}_${index})`}
                key={index}
              />
            ))
          }
        </g>
        <clipPath id={`clip_${instanceId}`}>
          <polygon points={scaledVertices.map((v) => `${v.x},${v.y}`).join(' ')} />
        </clipPath>
        <line
          ref={arrowRef}
          stroke={alpha(palette.text.primary, 0.2)}
          strokeWidth={arrowStrokeWidth}
          markerEnd="url(#arrowhead)"
        />
        <polygon
          ref={triangleRef}
          fill="transparent"
          stroke={alpha(palette.text.primary, 0.2)}
          strokeWidth={triangleStrokeWidth}
          strokeLinejoin="round"
          style={{cursor: 'pointer'}}
          points={scaledVertices.map((v) => `${v.x},${v.y}`).join(' ')}
          aria-label="Triangle"
        />
        <circle
          ref={initialPointRef}
          r={pointRadius}
          fill={palette.grey[500]}
          stroke={palette.background.paper}
          strokeWidth={pointStrokeWidth}
          style={{cursor: 'pointer'}}
          onClick={handleReset}
          aria-label="Initial Point"
          role="button"
        />
        <circle
          ref={dragPointRef}
          r={pointRadius}
          fill={palette.text.primary}
          stroke={palette.background.paper}
          strokeWidth={pointStrokeWidth}
          style={{cursor: 'grab'}}
          aria-label="Draggable Point"
          role="slider"
          aria-valuemin={0}
          aria-valuemax={1}
          aria-valuenow={barycentricCoords.reduce((a, b) => a + b, 0)}
          {...dataTestId('barycentric_triangle_draggable_point')}
        />
        {iconVertices.map((vertice, index) => (
          <g key={index}>
            <circle
              r={verticeIndicatorRadius}
              fill={alpha(verticeStyles[index].color, 0.1)}
              stroke={alpha(verticeStyles[index].color, 0.2)}
              cx={vertice.x}
              cy={vertice.y}
              style={{cursor: 'pointer'}}
              aria-label={`Vertex icon ${index + 1}`}
              role="button"
            />
            <foreignObject
              x={vertice.x - verticeIndicatorRadius}
              y={vertice.y - verticeIndicatorRadius}
              width={verticeIndicatorRadius * 2}
              height={verticeIndicatorRadius * 2}
            >
              <Tooltip title={verticeStyles[index].label ?? ''} placement="top">
                <IconButton
                  onClick={() => {
                    const newCoordinates: BarycentricCoordinates = [0, 0, 0]
                    newCoordinates[index] = 1
                    setBarycentricCoords(newCoordinates)
                  }}
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: verticeStyles[index].color,
                    '& svg': {
                      fontSize: 16
                    }
                  }}
                >
                  {verticeStyles[index].icon}
                </IconButton>
              </Tooltip>
            </foreignObject>
          </g>
        ))}
      </svg>
    </div>
  )
}
