import {mergeSx} from '@hconnect/uikit/src/lib2'
import {Checkbox, FormControlLabel} from '@mui/material'
import type {FormControlLabelProps} from '@mui/material'

interface PlannerCheckboxProps
  extends Pick<FormControlLabelProps, 'inputRef' | 'disabled' | 'onBlur' | 'onKeyDown' | 'sx'> {
  label?: string
  ['data-test-id']?: string
  value: boolean
  onChange?: (checked: boolean) => void
}

export const PlannerCheckbox = ({
  label,
  value,
  sx,
  onChange,
  ...otherProps
}: PlannerCheckboxProps) => {
  return (
    <FormControlLabel
      sx={mergeSx(!label ? {ml: 0, mr: 0} : {ml: 0}, sx)}
      control={<Checkbox />}
      label={label}
      checked={value}
      onChange={(e, checked) => onChange?.(checked)}
      {...otherProps}
    />
  )
}
