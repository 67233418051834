interface AssetKPIs {
  runtimeInHours: number
  producedTonnes: number
}

export interface AssetCapacity {
  materials: Record<string, AssetKPIs>
  maintenance: Pick<AssetKPIs, 'runtimeInHours'>
  // total runtime for the asset in hours including maintenance
  runtimeInHours: number
}

export interface AssetCapacityResponse {
  assets: Record<string, AssetCapacity>
}
