import moment from 'moment-timezone'

import {usePlantConfigQuery} from '../hooks/api'
import {usePlannerQuery} from '../hooks/api/usePlannerQuery'

/**
 * temporary solution to check if optimized plan is available
 * the logic should be replace when optimizer status endpoint is implemented
 */

export const useOptimizedPlanAvailable = (plantCode: string | undefined) => {
  const {data: plantConfig} = usePlantConfigQuery(plantCode)
  const {timezone_id: timezoneId, created_at: createdAt} = plantConfig ?? {}

  const start =
    timezoneId && createdAt
      ? moment.utc(createdAt).tz(timezoneId).startOf('day').toISOString()
      : undefined
  const end = start ? moment(start).add(1, 'day').toISOString() : undefined

  const {data: schedule} = usePlannerQuery(
    'schedule',
    [{plantCode: plantCode!, start: start!, end: end!}],
    {enabled: Boolean(plantCode && start && end), useErrorBoundary: false}
  )

  return schedule?.isOptimizedScheduleAvailable ?? false
}
