import {AssetsHistory} from '@hconnect/common/types'
import {useCallback} from 'react'

import {useUrlParam} from '../../../hooks/useUrlParam'
import {getBaseLoadPowerConsumptionFromHistoryAssets} from '../../../selectors/assets'
import {MomentRange} from '../../../selectors/time'
import {usePlannerQuery} from '../usePlannerQuery'

interface HistoryBaseLoadQueryParams {
  timeFrame: MomentRange
}
/**
 * hook to retrieve base load power consumption from assets history
 * NOTE: baseload value would be from the recent history entry in the given time frame
 */
export const useHistoryBaseLoadQuery = ({timeFrame: [from, to]}: HistoryBaseLoadQueryParams) => {
  const plantCode = useUrlParam('plantCode')
  const selector = useCallback(
    (assetsHistory: AssetsHistory) =>
      getBaseLoadPowerConsumptionFromHistoryAssets(assetsHistory, to),
    [to]
  )
  const {data: baseLoad} = usePlannerQuery(
    'assetsHistory',
    [plantCode, from.toISOString(), to.toISOString()],
    {select: selector}
  )

  return baseLoad ?? 0
}
