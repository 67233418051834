import {roundTo15MinIntervalStart} from '@hconnect/common/utils'
import {Box} from '@mui/material'
import {Moment} from 'moment-timezone'
import React from 'react'

import {usePlanningChartScheduleData} from '../../../app/page-planning/dailyPlanning/PlanningChartScheduleDataProvider'
import {usePlanningChartStartEnd} from '../../../app/page-planning/dailyPlanning/PlanningChartStartEndProvider'
import {NOW_INDICATOR_REFRESH_INTERVAL_MS} from '../../constants'
import {TimeScaleFn} from '../../helpers/scale'
import {useScheduleQuery} from '../../hooks/api/schedules/useScheduleQuery'
import {useSubmitSchedule} from '../../hooks/api/schedules/useSubmitSchedule'
import {useCurrentTime} from '../../hooks/useCurrentTime'
import {usePlantConfig} from '../../hooks/usePlantConfigData'

import {GanttChartDataGrid} from './GanttChartDataGrid'

interface GanttChartRowsProps {
  hoursList: Moment[]
  startOfPlan: Moment
  endOfPlan: Moment
  stepWidth: number
  xScale: TimeScaleFn
  isReadOnly: boolean
  shouldShowColorCodedElectricityPrices: boolean
  shouldShowCurrentTime?: boolean
  shouldRoundCurrentTime?: boolean
}

export const GanttChartRows: React.FC<GanttChartRowsProps> = React.memo(
  ({
    startOfPlan,
    endOfPlan,
    stepWidth,
    xScale,
    isReadOnly,
    shouldRoundCurrentTime,
    shouldShowCurrentTime
  }) => {
    const {timezone_id: timezoneId} = usePlantConfig()
    const {isLoading: isScheduleSubmitting} = useSubmitSchedule()
    const {scheduleId} = usePlanningChartScheduleData()
    const {isFetching: isScheduleFetching} = useScheduleQuery({
      range: [startOfPlan, endOfPlan],
      scheduleId
    })
    const shouldDisableGanttRows = isScheduleSubmitting || isScheduleFetching || isReadOnly

    const {
      visibleHoursList,
      hoursRange: [displayedHoursOffset]
    } = usePlanningChartStartEnd()

    const currentTime = useCurrentTime({
      timezoneId,
      intervalMs: NOW_INDICATOR_REFRESH_INTERVAL_MS,
      isDisabled: !shouldShowCurrentTime
    })
    const currentTimeAfterRounding = shouldRoundCurrentTime
      ? roundTo15MinIntervalStart(currentTime)
      : currentTime

    // could be negative if current time is before start of plan
    const nowOffset = xScale(currentTimeAfterRounding)
    return (
      <Box sx={{position: 'absolute', top: 0, left: 0}}>
        <GanttChartDataGrid
          visibleHoursList={visibleHoursList}
          displayedHoursOffset={displayedHoursOffset}
          endOfPlan={endOfPlan}
          stepWidth={stepWidth}
          startOfPlan={startOfPlan}
          nowOffset={nowOffset}
          isDisabled={shouldDisableGanttRows}
        />
      </Box>
    )
  }
)

GanttChartRows.displayName = 'GanttChartRows'
